<template>
  <v-main class="grey lighten-3">
    <div id="core-view">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
  </v-main>
</template>

<script>
  export default {
    name: 'CoreView',
    metaInfo: {
      title: null,
    },
  }
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.25s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
